.card[data-card-height="cover-full"],
.card[data-card-height="cover"] {
  height: 100vh;
}

.card[data-card-height="cover-card"] {
  height: calc(100vh - 200px);
}

#form1a-select {
  border-color: rgba(255, 255, 255, 0.1) !important;
  font-size: 15px;
  color: #fff !important;
}

.custom_play_btn {
  border: 0;
  width: 100px;
  border-radius: 4px;
  padding: 3px 5px;
  margin: 8px 7px 12px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid #e9ac20; */
  color: white;
  box-shadow: 0 1px 3px rgb(0, 0, 0, 0.5);
  background: #10396a !important;
  border: 2px solid #e6960c;
}

.slider-wrapper {
  margin-bottom: 20px;
  position: relative;
}

.slider-wrapper .rcs_sliderSet {
  box-shadow: 0 12px 12px rgba(0, 0, 0, 0.3) !important;
  border-radius: 20px !important;
}

.rcs_slideCon .itemWrapper::after {
  transform: scale(0.93) translateY(15px) translateZ(-40px);
  filter: blur(12px);
}

.balance_wrapper {
  font-size: 13px;
  font-family: inherit;
  padding: 0px;
}

.home_balance {
  overflow: hidden;
}

.pbalance {
  overflow: hidden;
}

.balance_btns {
  text-align: center;
  width: 100%;
  display: flex;
  column-gap: 8px;
  justify-content: space-between;
  align-items: center;
}

.balance_btns .btns {
  text-align: center;
  background-image: linear-gradient(to bottom right, #8b25f4, #f422f7);
  margin-top: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 41px;
  padding: 5px 2.5rem;
  flex: 0.5;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
}

.ant-modal-content {
  background: #482e53 !important;
}

.ant-modal-header {
  background: transparent !important;
}

.ant-modal-title {
  color: white !important;
}
.ant-modal-close {
  color: white !important;
}

.modal-box .ant-input-password {
  background: transparent !important;
}

.modal-box input {
  background: transparent !important;
  color: white !important;
}

.modal-box input::placeholder {
  color: #8f8f8f;
}

.modal-box .ant-input-password-icon {
  color: white !important;
}

.balance_btns .btns img {
  margin-right: 10px;
}
.custom_bank p,
.custom_contact p {
  font-size: 15px;
  color: #ffffff !important;
  text-align: left;
  padding-left: 10px;
  padding-top: 10px;
  margin-bottom: 5px;
  font-weight: 100;
}
.custom_contact .box-contact {
  width: 100%;
  height: auto;
  margin: 5px 0;
  text-align: center;
  position: relative;
}
.custom_contact .info-contact {
  width: 100%;
  height: auto;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  left: 10px;
  top: 0;
  padding: 6px;
  display: flex;
}
.custom_contact .info-contact .text-contact {
  font-size: 14px;
  color: #ffffff;
  padding: 1px 5px;
}
.custom_space {
  width: 100%;
  height: 10px;
  margin-top: 10px;
  background-image: linear-gradient(to right, #200929, #e423f7, #200929);
}
.balance_btns .btns i {
  margin: 4px -23px;
  position: absolute;
  font-size: 18px;
}

.page-title .logo {
  height: 46px;
  width: 100%;
  margin: 5px 10px;
}

.head_btn {
  display: flex;
  align-items: center;
}

.head_btn a {
  margin: 2px;
  background-image: linear-gradient(to bottom right, #8b25f4, #f722b9);
  color: #ffffff;
  border: 1px solid #f400ff !important;
  padding: 0px 10px;
  border-radius: 41px !important;
  font-size: 14px;
}

.btn_green {
  color: white !important;
  background-image: linear-gradient(to bottom right, #8b25f4, #f722b9);
  border-radius: 4px !important;
  border: 0;
  box-shadow: 0 1px 3px rgb(0, 0, 0, 0.5);
  font-size: 15px !important;
  line-height: 20px !important;
  padding: 8px 0;
  font-weight: bold;
  text-transform: uppercase;
}

.register_btn {
  width: 150px;
  color: white !important;
  background-image: linear-gradient(to bottom right, #8b25f4, #f722b9);
  border: 1px solid #f400ff;
  border-radius: 4px !important;
}

.badge_anima {
  background: #f38120;
  margin: 3px -40px;
  position: absolute !important;
  color: #fff !important;
  line-height: 20px;
  width: 20px;
  text-align: center;
  border-radius: 10px;
  top: 5px;
  right: 50%;
  font-weight: 700 !important;
  font-size: 12px !important;
  opacity: 1 !important;
}

.main_pulse {
  animation: pulse 1s infinite ease-in-out alternate;
}

marquee {
  background-image: url("../src/image/marquee.jpg");
  font-size: 11px;
  padding: 7px;
  color: #a9a9a9;
  letter-spacing: 0.5px;
  height: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
  line-height: 1;
  background-size: 100% 100%;
  z-index: 2;
  position: relative;
  overflow: hidden;
}

#settings {
  padding: 10px;
  font-size: 18px !important;
  font-weight: 700 !important;
  padding-top: 20px;
}

.setting_head {
  color: #e022f6 !important;
  border-bottom: 2px solid;
  padding-bottom: 10px !important;
}

#settings a {
  padding: 15px 0 !important;
  border-bottom: 1px solid;
  color: white;
  display: block;
  font-size: 15px;
}

.color_yellow {
  color: #d4a948 !important;
}

#deposit_cards {
  padding: 15px;
  overflow: hidden;
}

#deposit_cards .pay_menu {
  background: transparent;
  display: block;
  padding: 7px;
  border-radius: 4px;
}

#deposit_cards .pay_menu img {
  width: 100%;
}

.custom-icon {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.custom-icon i {
  font-size: 22px;
  color: #f1cf6f;
  margin-right: 10px;
}

.custom_title {
  margin-top: 0px !important;
  padding: 20px !important;
  padding-top: 20px !important;
  background-color: #2f005d !important;
  display: flex;
  justify-content: center;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.jackpot {
  width: 100%;
  text-align: center;
  padding-top: 15px;
}

.custom_icon_img {
  width: 30px;
  height: 30px;
  background: no-repeat center center;
  background-size: 100% 100%;
  background-image: url(./images/menu-resp.svg);
}
